// This is for temporary use only.
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

// initialize an empty api service that we'll inject endpoints into later as needed
export const apiSlice = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem("token");

      // headers.set("x-api-key", import.meta.env.VITE_X_API_KEY as string);
      headers.set("Content-Type", "application/json")
      headers.set( "Accept", "application/json")
    
      if (token) {
        headers.set("authorization", `Bearer ${token.replace(/['"]+/g, "")}`);
      }

      return headers;
    }
  }),
  endpoints: () => ({})
});

// import { Mutex } from "async-mutex";
// // import axios from "axios";
// import { logout } from "@/utils/helpers/authentication";
// import { BaseQueryApi, createApi, FetchArgs, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

// // const fetchAccessToken = async (refreshToken: string) => {
// //   try {
// //     const response = await axios.post(`${import.meta.env.VITE_BASE_URL}/token/refresh`, {
// //       refresh: refreshToken
// //     });

// //     if (response.data?.data?.access) {
// //       return response.data.data;
// //     } else {
// //       return null;
// //     }
// //   } catch (error) {
// //     console.error("Failed to refresh access token", error);
// //     return null;
// //   }
// // };

// const mutex = new Mutex();

// const baseQuery = fetchBaseQuery({
//   baseUrl: import.meta.env.VITE_BASE_URL as string,
//   prepareHeaders: async (headers) => {
//     const token = localStorage.getItem("token");
//     headers.set("x-api-key", import.meta.env.VITE_X_API_KEY as string);
//     if (token) {
//       headers.set("authorization", `Bearer ${token.replace(/['"]+/g, "")}`);
//     }
//     return headers;
//   }
// });

// const baseQueryWithReauth = async (
//   args: string | FetchArgs,
//   api: BaseQueryApi,
//   extraOptions: Record<string, never>
// ) => {
//   await mutex.waitForUnlock();
//   const result = await baseQuery(args, api, extraOptions);

//   // this will be used when refreshToken is activated
//   // const refreshToken = localStorage.getItem("refresh_token");
//   // if (result.error && result.error.status === 403) {
//   //   // checking whether the mutex is locked
//   //   if (!mutex.isLocked()) {
//   //     const release = await mutex.acquire();
//   //     try {
//   //       const refreshResult = refreshToken && (await fetchAccessToken(refreshToken));

//   //       if (refreshResult) {
//   //         localStorage.setItem("token", refreshResult?.token);
//   //         localStorage.setItem("refresh_token", refreshResult?.refresh);

//   //         // retry the initial query
//   //         result = await baseQuery(args, api, extraOptions);
//   //       } else {
//   //         logout();
//   //         window.location.href = "/signin";
//   //       }
//   //     } finally {
//   //       // release must be called once the mutex should be released again.
//   //       release();
//   //     }
//   //   } else {
//   //     // wait until the mutex is available without locking it
//   //     await mutex.waitForUnlock();
//   //     result = await baseQuery(args, api, extraOptions);
//   //   }
//   // } else if (result.error && result.error.status === 401) {
//   //   logout();
//   //   window.location.href = "/signin";
//   // }

//   if (result.error && result.error.status === 401) {
//     logout();
//     window.location.href = "/signin";
//   }
//   return result;
// };

// export const apiSlice = createApi({
//   baseQuery: async (args, api, extraOptions) => {
//     const result = await baseQueryWithReauth(args, api, extraOptions);
//     return result;
//   },
//   endpoints: () => ({})
// });
