import React, { useEffect, useState } from "react";
import { endpoints } from "../../admin/redux/ConfirmedPayment/confirmedPaymentApi";
import Pagination from "../components/Pagination/Pagination";
import Table from "../components/Table/Table";
import ExportTransactions from "../components/ExportTransactions";
import { createSearchParams, useNavigate } from "react-router-dom";
import useGetUrlQuery from "../hooks/useGetUrlQuery";
import { useDispatch } from "react-redux";
import PrintReceiptModal from "../components/PrintReceiptModal";
import { formatDateTime } from "../../utils/formatDateTime";


const PaymentOnly = () => {
  const [isFetching, setIsFetching] = useState(false);
  const [data, setData] = useState([]);
  const urlSearchParams = new URLSearchParams(window.location.search);
  const [selectedFilters, setSelectedFilters] = useState({
    gen_order_id: { label: "Order ID", dbColumn: "gen_order_id", isChecked: false },
    tip_amount: { label: "Payment Type", dbColumn: "payment_type", isChecked: false },
    tip_waiter: { label: "Transaction ID", dbColumn: "transaction_id", isChecked: false },
    invoice_date: { label: "Payer Name", dbColumn: "payer_name", isChecked: false },
    total_bill: { label: "Total Bill", dbColumn: "total_bill", isChecked: false },
  });
  const currentPage = Number(urlSearchParams.get("page")) || 1;
  const [printModalVisible, setPrintModalVisible] = useState(false);
  // const [selectedReceipt, setSelectedReceipt] = useState(null);
  const [totalNumberOfPages, setTotalNumberOfPages] = useState(0);
  const navigate = useNavigate();
  const urlQuery = useGetUrlQuery();
  const dispatch = useDispatch();

  useEffect(() => {
    getAllTransactions();
  }, [currentPage, dispatch]);

  const getAllTransactions = async () => {
    setIsFetching(true);
    const userData = JSON.parse(localStorage.getItem("userData"));
    const user_type_id = userData.user_type_id;

    try {
      if (user_type_id == "8" || user_type_id == "9" || user_type_id == "10") {
        const response = await dispatch(
          endpoints.getOwnerQrMidTransaction.initiate()
        ).unwrap();
        console.log(response);
        if (response.data) {
          const payment = await dispatch(
            endpoints.getPaymentOnly.initiate({ id: response?.data?.mid })
          ).unwrap();
          setData(payment?.data?.data);
          setTotalNumberOfPages(payment?.data?.last_page);
          setIsFetching(false);
        }
      } else {
        const qrPayMid = userData?.user?.mid;
        const payment = await dispatch(
          endpoints.getPaymentOnly.initiate({ id: qrPayMid })
        ).unwrap();
        setData(payment?.data?.data);
        setTotalNumberOfPages(payment?.data?.last_page);
        setIsFetching(false);
      }
    } catch (error) {
      setData([]);
      setTotalNumberOfPages(0);
      setIsFetching(false);
    }
  };

  // const handlePrintReceipt = async (id) => {
  //   try {
  //     const response = await dispatch(
  //       endpoints.printTransaction.initiate(id)
  //     ).unwrap();
  //     console.log(response);
  //     setSelectedReceipt(response.data);
  //     setPrintModalVisible(true);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const handlePageClick = (page) => {
    const query = { ...urlQuery, page: page };
    navigate(`?${createSearchParams(query).toString()}`);
  };

  return (
    <>
      <div className="content-wrapper">
        <div className="container-fluid">
          <div className="form-head dashboard-head d-md-flex d-block mb-5 align-items-start">
            <h2 className="dashboard-title me-auto">Payment Only QR</h2>
            <ExportTransactions selectedFilters={selectedFilters} setSelectedFilters={setSelectedFilters}/>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="table-responsive">
                <div
                  id="example6_wrapper"
                  className="dataTables_wrapper no-footer"
                >
                  <Table
                    data={data}
                    columns={[
                      { header: "S/N", accessorFn: (_, index) => index + 1 },
                      { header: "ORDER ID", accessorKey: "order_id" },
                      { header: "TIP AMOUNT", accessorKey: "payment_type" },
                      {
                        header: "TIP WAITER",
                        accessorKey: "transaction_id",
                      },
                      {
                        header: "INVOICE DATE",
                        accessorFn: (row) => formatDateTime(row.created_at),
                      },
                      { header: "TOTAL BILL", accessorKey: "amount" },
                      //   {
                      //     header: "ACTION",
                      //     cell: ({ row }) => {
                      //       const id = row.original.id;
                      //       return (
                      //         <div className="action-buttons d-flex justify-content-end">
                      //           <Link
                      //             to="#"
                      //             className="btn btn-success light me-2"
                      //             onClick={() => {
                      //               console.log(
                      //                 "Print icon clicked for id:",
                      //                 row.original.id
                      //               );
                      //               handlePrintReceipt(row.original.id);
                      //             }}
                      //           >
                      //             {SVGICON.Print}
                      //           </Link>
                      //         </div>
                      //       );
                      //     },
                      //   },
                    ]}
                    loading={isFetching}
                  />
                  <Pagination
                    totalNumberOfPages={totalNumberOfPages}
                    onPageClick={handlePageClick}
                    activePage={currentPage}
                    showSizePicker={false}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <PrintReceiptModal
        show={printModalVisible}
        onHide={() => setPrintModalVisible(false)}
        // receipt={selectedReceipt}
      />
    </>
  );
};

export default PaymentOnly;
