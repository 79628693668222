import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import {
  endpoints,
  useGetCategoriesQuery,
} from "../../admin/redux/MenuList/menuList";
import templateFile from "../../frontend/images/food_menu_upload.xlsx";
import axios from "axios";

const BulkFoodMenuUpload = () => {
  const dispatch = useDispatch();
  const [categoryId, setCategoryId] = useState("");
  const [file, setFile] = useState(null);
  const { data: categoriesData } = useGetCategoriesQuery(undefined, {});

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleCategoryChange = (e) => {
    setCategoryId(e.target.value);
  };

  const handleUpload = async (e) => {
    e.preventDefault();

    if (!categoryId || !file) {
      toast.error("Please select a category and a file");
      return;
    }

    const formData = new FormData();
    formData.append("category_id", categoryId);
    formData.append("file", file);

    try {
        // Use axios to make the API call
        const token = localStorage.getItem('token'); // Assuming you store the token in localStorage
        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/food_menu_upload`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
              'Authorization': `Bearer ${token.replace(/['"]+/g, '')}`
            }
          }
        );
  
        toast.success(response.data.message || "Food menu uploaded successfully");
        // Reset form
        setCategoryId("");
        setFile(null);
        // Reset file input
        document.getElementById("food_file").value = "";
      } catch (error) {
        console.error("Error uploading food menu:", error);
        toast.error(error.response?.data?.message || "Error uploading food menu");
      }
    };
  return (
    <div className="container mx-auto px-4 py-8">
      <h2 className="text-2xl font-bold mb-6">Bulk Food Menu Management</h2>

      {/* Template Download Section */}
      <div className="card mb-4">
        <div className="card-body">
          <h3 className="h4 text-danger mb-3">Download Template</h3>
          <p className="mb-3">
            In order to upload your food menu, please download the template by
            clicking the button below.
          </p>

          <a
            href={templateFile}
            download="food_menu_upload.xlsx"
            className="btn btn-primary"
          >
            Download Template
          </a>
        </div>
      </div>

      {/* Upload Section */}
      <div className="card">
        <div className="card-body">
          <h3 className="h4 text-primary mb-3">Upload Food Menu</h3>
          <p className="mb-3 text-muted">
            NB: Ensure you only upload a copy of the downloaded template
          </p>
          <form onSubmit={handleUpload}>
            <div className="mb-3">
              <label className="form-label" htmlFor="category">
                Select Category
              </label>
              <select
                id="category"
                value={categoryId}
                onChange={handleCategoryChange}
                className="form-select"
                required
              >
                <option value="">Select a category</option>
                {categoriesData?.data?.map((category) => (
                  <option key={category.id} value={category.id}>
                    {category.category}
                  </option>
                ))}
              </select>
            </div>
            <div className="mb-3">
              <label className="form-label" htmlFor="food_file">
                Select File
              </label>
              <input
                type="file"
                id="food_file"
                onChange={handleFileChange}
                accept=".xls, .xlsx"
                className="form-control"
                required
              />
            </div>
            <button type="submit" className="btn btn-success">
              Upload Food Menu
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default BulkFoodMenuUpload;
