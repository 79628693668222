import { configureStore } from "@reduxjs/toolkit";

import { apiSlice } from "./api/apiSlice";

// Annotate the store variable with the RootState type
const store = configureStore({
  reducer: {
    [apiSlice.reducerPath]: apiSlice.reducer,
    // Add other reducers as needed
  },

  // add middleware to the store
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(apiSlice.middleware),

  // Enable Redux DevTools Extension
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  devTools: process.env.NODE_ENV === "development" ? true : false
});

// Infer the `RootState` and `AppDispatch` types from the store itself
// export type RootState = ReturnType<typeof store.getState>;
// export type AppDispatch = typeof store.dispatch;

export default store;
