import React, { useEffect, useState, useCallback } from "react";

import { useDispatch } from "react-redux";
import { endpoints } from "../../admin/redux/Orders/orders";
import { Modal, Button } from "react-bootstrap";

const OrderDetailsModal = ({ show, onHide, order }) => {
  const [selectedReceipt, setSelectedReceipt] = useState(null);
  const [printModalVisible, setPrintModalVisible] = useState(false);
  const dispatch = useDispatch();

  if (!order) return null;
  // console.log(order.order_details);
  const handlePrintReceipt = async (id) => {
    try {
      const response = await dispatch(
        endpoints.printTransaction.initiate(id)
      ).unwrap();
      console.log(response);
      setSelectedReceipt(response.data);
      setPrintModalVisible(true);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <Modal show={show} onHide={onHide} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Order Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="mb-3">
          <strong>ORDER ID:</strong> {order.gen_order_id}
        </div>
        <div className="mb-3">
          <strong>TABLE NUMBER:</strong>{" "}
          <span className="text-success">{order.table_number}</span>
        </div>
        <div className="mb-3">
          <strong>STATUS:</strong>{" "}
          {order.is_processed == 1 ? "Processing" : "Not processed"}
        </div>
        <div className="mb-3">
          <strong>TIPPED WAITER:</strong> {order.waiters_name}
        </div>
        <div className="mb-3">
          <strong>TIPPED AMOUNT: ₦</strong>{" "}
          {order.tipped_amount ? order.tipped_amount : 0}
        </div>
        <div className="mb-3">
          <strong>PAYMENT STATUS:</strong> {order.is_paid ? "PAID" : "UNPAID"}
        </div>
        <div className="mb-3">
          <strong>ORDER NOTES:</strong> {order.order_notes}
        </div>
        <table className="table table-bordered">
          <thead className="bg-primary text-white">
            <tr>
              <th>S/N</th>
              <th>Food Item</th>
              <th>Quantity</th>
              <th>Price</th>
            </tr>
          </thead>
          <tbody>
            {Array.isArray(order?.order_details) &&
              order?.order_details.map((item, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{item.food_type}</td>
                  <td>x{item.quantity}</td>
                  <td>₦{item.unit_price}</td>
                </tr>
              ))}
          </tbody>
        </table>
        <div className="text-end mt-3">
          <h4>Total: ₦{order.total_price}</h4>
          {order.discount > 0 && (
            <p className="text-muted">{order.discount} discount applied</p>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
        <Button variant="primary" onClick={() => handlePrintReceipt(order.id)}>
          Print Order
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default OrderDetailsModal;
